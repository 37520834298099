<template>
    <div class="my-checkbox" :class="{ 'checked': isChecked }" @click="toggleCheck"></div>
</template>
<script setup>
import { ref, defineEmits, onMounted, defineProps } from 'vue';

const props = defineProps(['checked'])
const emit = defineEmits(['checked']);
const isChecked = ref(props.checked);

onMounted(() => {

})

const toggleCheck = () => {
    isChecked.value = !isChecked.value;
    emit('checked', isChecked.value);
};
</script>
<style scoped>
.my-checkbox {
    border: 1px solid rgba(0, 0, 0, 0.466);
    height: 2rem;
    width: 2rem;
    align-self: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
}

.my-checkbox.checked {
    background-color: #0000007c;
    border-color: #0000007c;
}

.my-checkbox.checked::after {
    content: '\2714';
    color: white;
    font-size: 1rem;
}
</style>