<template>
    <div class="goal-item" :style="{backgroundColor: goal.color}">
        <div style="display: flex; align-items: center;">

            <div class="streak">{{goal.streak }}</div>
            <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; gap: 5px;">
                <div class="description">
                    {{ goal.description }}
                </div>
                <div class="streak-days">
                    <span v-for="(day, index) in days" :key="day.value" class="day-circle" :style="{fontWeight: getTextWeight(day.value), border: getBorderStyle(), borderRadius: getBorderRadius(index), backgroundColor: getBKGColor(day.text, index)}">{{ day.text }}</span>
                </div>
            </div>
        </div>
        <MyCheckButton :checked="isCheckedToday" @checked="checked" />
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import MyCheckButton from './MyCheckButton.vue';

const emit = defineEmits(['checked'])
const props = defineProps(['goal', 'today']);
const goal = ref(props.goal);
const today = ref(props.today);
const todayString = ref(props.today.toDateString().slice(0,3));
console.log(goal.value.datesCompleted)
const completedIndeces = ref(goal.value.datesCompleted?.map(date => date.getDay()));

console.log(completedIndeces.value)
//const isChecked = ref(false)
const days = ref([
    {
        value: 'Sun',
        text: 'S',
    },
    {
        value: 'Mon',
        text: 'M',
    },
    {
        value: 'Tue',
        text: 'T',
    },
    {
        value: 'Wed',
        text: 'W',
    },
    {
        value: 'Thu',
        text: 'T',
    },
    {
        value: 'Fri',
        text: 'F',
    },
    {
        value: 'Sat',
        text: 'S',
    }
])

//const currentDay = ref(props.today.slice(0,3));

const getTextWeight = (dayValue) => {
    if(dayValue == todayString.value) {
        return "bold";
    } else {
        console.log(dayValue, todayString.value)
    }
}

const getBorderStyle = () => {
    
    return ""
}

const getBKGColor = (dayText, index) => {
    let color = "";
    for(let date of goal.value.datesCompleted) {
        if(date?.getDay() == index) {
            color = "white";
        }
    }
    return color;
}

const getBorderRadius = (index) => {
    if(index == 0 && completedIndeces.value.includes(index + 1)) {
        return "20px 0 0 20px";
    } else if(index == 0) {
        return "20px 20px 20px 20px";
    } else if(index == 6 && completedIndeces.value.includes(index - 1)) {
        return "0 20px 20px 0";
    } else if(index == 6) {
        return "20px 20px 20px 20px";
    } else if(completedIndeces.value.includes(index-1) && completedIndeces.value.includes(index+1)) {
        return "0";
    } else if(completedIndeces.value.includes(index-1)) {
        return "0 20px 20px 0";
    } else if(completedIndeces.value.includes(index+1)) {
        return "20px 0 0 20px";
    } else {
        return "20px 20px 20px 20px";
    }
}

const isCheckedToday = computed(() => {
    return goal.value.datesCompleted.some(date => 
        date.toDateString() === today.value.toDateString()
    );
});

const checked = (isChecked) => {
    emit('checked', goal.value.id, isChecked);
}
</script>

<style scoped>
.goal-item {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    margin: 1rem 0 1rem 0;
    /* border: 1px solid grey; */
    border-radius: 10px;
    background-color: white;
    box-shadow: 2px 2px 5px #d7d7d7;
    height: 6rem;
}
.streak {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10px;
    /* border: 1px solid black; */
    /* border-radius: 50%; */
    border-radius: 10px;
    width: 60px;
    height: 60px;
    font-size: 4rem;
    font-weight: bold;
}

.description {
    margin-left: .5rem;
    font-size: 2rem;
}

.streak-days {
    display: flex;
    font-size: 1.5rem;
}

.day-circle {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

</style>