<template>
    <div @click="advance">Goals</div>
    <GoalComponent v-for="goal in goals" :key="goal.id" :goal="goal" :today="today" @checked="checked"/>
    <SpeedDial :model="items" direction="up" style="position: fixed; bottom: 100px; right: 30px; " />

    <PrimeDialog v-model:visible="addGoalVisible" modal header="Add New Goal">
        <div style="margin: 10px 0 10px 0;">
            <InputText v-model="newGoalDescription" placeholder="Enter goal description" />
        </div>
        <div class="flex justify-end gap-2">
            <PrimeButton type="button" label="Cancel" severity="secondary" @click="addGoalVisible = false"></PrimeButton>
            <PrimeButton type="button" label="Save" @click="saveNewGoal"></PrimeButton>
        </div>
    </PrimeDialog>

    <PrimeDialog v-model:visible="editGoalsVisible" modal header="Edit Goals" style="height: 50%; width: 80%;">
        <div v-for="goal in goals" :key="goal.id" class="flex justify-between items-center mb-2">
            <div style="display: flex; justify-content: space-between; align-items: center; margin: 8px; padding: 8px;">
                <span>{{ goal.description }}</span>
                <PrimeButton 
                  severity="danger" 
                  @click="deleteGoal(goal.id)"
                  :disabled="goalsToDelete.includes(goal.id)"
                >
                  Delete
                </PrimeButton>
            </div>
        </div>
        <div class="flex justify-end gap-2 mt-4">
            <PrimeButton type="button" label="Cancel" severity="secondary" @click="cancelEditGoals"></PrimeButton>
            <PrimeButton type="button" label="Save" @click="saveEditedGoals"></PrimeButton>
        </div>
    </PrimeDialog>
</template>

<script setup>
import { ref } from 'vue';
import GoalComponent from './GoalComponent.vue';
import PrimeDialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useGoalStore } from '../stores/GoalStore.js';
import { storeToRefs } from 'pinia';

const goalStore = useGoalStore();
const { goals } = storeToRefs(goalStore);

const today = ref(new Date());
const num = ref(18)
const advance = () => {
    num.value ++;
    today.value = new Date(2024, 8, num.value)
}
const addGoalVisible = ref(false);
const newGoalDescription = ref('');
const editGoalsVisible = ref(false);
const goalsToDelete = ref([]);

const items = ref([
    {
        label: 'Add',
        icon: 'fas fa-plus',
        command: () => {
            addGoalVisible.value = true;
        }
    },
    {
        label: 'Delete',
        icon: 'fas fa-pencil',
        command: () => {
            editGoalsVisible.value=true;
        }
    },
])

const saveNewGoal = () => {
    if (newGoalDescription.value.trim()) {
        goalStore.addGoal({
            id: goals.value.length + 1,
            description: newGoalDescription.value,
            streak: 0,
            color: "#FF8A8A",
            datesCompleted: []
        });
        addGoalVisible.value = false;
        newGoalDescription.value = '';
    }
};

const checked = (id, isChecked) => {
    goalStore.checked(id, isChecked)
};

const deleteGoal = (id) => {
    if (!goalsToDelete.value.includes(id)) {
        goalsToDelete.value.push(id);
    }
};

const cancelEditGoals = () => {
    goalsToDelete.value = [];
    editGoalsVisible.value = false;
};

const saveEditedGoals = () => {
    goalsToDelete.value.forEach(id => {
        goalStore.deleteGoal(id);
    });
    goalsToDelete.value = [];
    editGoalsVisible.value = false;
};

// ... rest of the code remains the same
</script>