<template>
    <div style="padding: 20px;">
        <PrimeDialog v-model:visible="addTaskVisible" modal header="Add Item" >
            <div style="margin: 10px 0 10px 0;">
                <InputText v-model="newTaskDescription" />
            </div>
            <div class="flex justify-end gap-2">
                <PrimeButton type="button" label="Cancel" severity="secondary" @click="addTaskVisible = false"></PrimeButton>
                <PrimeButton type="button" label="Save" @click="saveNewTask"></PrimeButton>
            </div>
        </PrimeDialog>
        <h1>Calendar</h1>
        <div>
            <VCalendar ref="calendar" timeZone="America/Chicago" locale="en-CA" expanded :attributes="attributes" @dayclick="setSelectedDay"/>
        </div>
        <div style="background-color: white; border-radius: 8px; margin-top: 10px; padding: 8px 10px 8px 10px; min-height: 4rem;">
            <TaskComponent v-for="(task, index) in taskList" :key="index" :description="task.description" :task="task" @updateTask="updateTask" />
        </div>
        <PrimeButton @click="addTaskVisible = true" style="position: absolute; bottom: 80px; right: 50px;">add</PrimeButton>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useTasksStore } from '../stores/TaskStore.js';
import TaskComponent from './TaskComponent.vue';

const calendar = ref();
const taskDates = ref();
const actualTaskStore = useTasksStore();
const taskList = ref();

onMounted(() => {
    actualTaskStore.getCachedTasks();
    getMonthTasks();
    taskList.value = actualTaskStore.getTasksByDay(selectedDateString.value)
})


const header = ref("Calendar");
const currentDate = ref(new Date());
const selectedDate = ref(currentDate.value);
const selectedDateString = computed(() => {
    return selectedDate.value.toLocaleDateString('en-CA').slice(0, 10);
})

const addTaskVisible = ref(false);
const newTaskDescription = ref();

// const taskDates = computed(() => {
//     let watcher = addTaskVisible.value;
//     console.log(watcher);
//     let tasks = actualTaskStore.getTasksByMonth(calendar.value.firstPage.month);
//     let dateObjects = [];
//     for (let task of tasks) {
    //         dateObjects.push(task.dateObj)
    //     }
//     return dateObjects;
// })

const attributes = ref([
    {
        key: 'current',
        highlight: true,
        dates: currentDate.value,
    },
    {
        key: 'selected',
    },
    {
        key: 'dots',
        dates: taskDates.value,
        dot: 'red',
    }

])


const saveNewTask = () => {
    actualTaskStore.addTask(newTaskDescription.value, selectedDateString.value)
    addTaskVisible.value = false;
    newTaskDescription.value = '';
}

const setSelectedDay = (day, mouseEvent) => {
    header.value = day.id;
    selectedDate.value = day.date;
    console.log(mouseEvent);
    let attribute = attributes.value.find(entry => entry.key == 'selected');
    attribute.highlight = {color: 'grey', fillMode: 'light'};
    attribute.dates = day.date;
    console.log('min date', calendar.value.firstPage.month )
    actualTaskStore.getTasksByMonth(calendar.value.firstPage.month)
}

const getMonthTasks = () => {
    let tasks = actualTaskStore.getTasksByMonth(calendar.value.firstPage.month);
    // Use a Set to store unique date objects
    if(tasks) {

        let uniqueDates = new Set();
        for (let task of tasks) {
            // Add each date to the Set
            uniqueDates.add(task.dateObj);
        }
        // Convert the Set back to an array of Date objects
        taskDates.value = Array.from(uniqueDates).map(dateString => new Date(dateString));
        console.log('monthTasks', taskDates.value);
        attributes.value[2].dates = taskDates.value;
    }
}

const updateTask = (task) => {
    console.log('update', task)
}

watch(addTaskVisible, () => {
    getMonthTasks();
})
watch(selectedDateString, () => {
    taskList.value = actualTaskStore.getTasksByDay(selectedDateString.value);
    console.log('this task list', taskList.value)
})



</script>