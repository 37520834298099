import { createApp } from 'vue';
import App from './App.vue';
import { vLongPress } from './directives/v-long-press';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import './registerServiceWorker';

import PrimeVue from 'primevue/config';
//import 'primevue/resources/themes/aura-light-green/theme.css';
import Aura from '@primevue/themes/aura';
import Button from "primevue/button";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import CheckBox from 'primevue/checkbox';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import SelectButton from 'primevue/selectbutton';
import SpeedDial from 'primevue/speeddial';


import { createPinia } from 'pinia';



const app = createApp(App);
const pinia = createPinia();
app.use(PrimeVue, {
    // Default theme configuration
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: '.app-dark',
            cssLayer: false
        }
    }
 });
app.use(VCalendar);
app.use(pinia);
app.component('PrimeButton', Button);
app.component('PrimeDialog', Dialog);
app.component('InputText', InputText);
app.component('CheckBox', CheckBox);
app.component('PrimeTabs', Tabs);
app.component('PrimeTab', Tab);
app.component('PrimeTabList', TabList);
app.component('PrimeTabPanels', TabPanels);
app.component('PrimeTabPanel', TabPanel);
app.component('SelectButton', SelectButton);
app.component('SpeedDial', SpeedDial);

// Register the v-long-press directive
app.directive('long-press', {
  mounted: vLongPress
});

app.mount('#app');