import { defineStore } from "pinia";

export const useTasksStore = defineStore('tasks', () => {
    
    let tasks = [];
    /* {
            dateObj:
            dateString:
            month:
            tasks:
        }
    */
    const addTask = (taskDescription, dateString) => {
        console.log('description', taskDescription);
        console.log('datestring', dateString);
        // Create a date object using the dateString
        // Use UTC to avoid timezone issues
        let dateObj = new Date(dateString + 'T00:00:00Z');
        
        // Adjust for local timezone offset
        dateObj = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
        let newTask = {
            dateObj: dateObj,
            dateString: dateString,
            description: taskDescription,
            completed: false,
        }
        console.log('newTask', newTask);
        let matchingDateEntry = tasks.find(entry => entry.dateString == dateString);
        // check to see if date entry already exists
        if (matchingDateEntry) {
            matchingDateEntry.tasks.push(newTask);
        } else {
            // if it doesn't then create it and add the new task to it
            let calendarDate = {
                dateObj: dateObj,
                dateString: dateString,
                month: Number(dateString.slice(5, 7)),
                tasks: [newTask],
            }
            console.log('calendarDate', calendarDate);
            tasks.push(calendarDate);
            localStorage.setItem('tasks', JSON.stringify(tasks))
        }
        console.log('tasks', tasks)
    }

    const getTasksByDay = (dateString) => {
        if (tasks.length > 0) {
            let matchingDateEntry = tasks.find(entry => entry.dateString == dateString);
            if (!matchingDateEntry) return [];
            console.log('tasksbyday', matchingDateEntry.tasks)
            return matchingDateEntry.tasks;
        } else {
            return [];
        }
    }

    const getTasksByMonth = (month) => {
        let monthTasks = [];
        if (tasks.length > 0) {
            for (let dateEntry of tasks) {
                if (dateEntry.month == month) {
                    for(let task of dateEntry.tasks) {
                        monthTasks.push(task)
                    }
                }
            }
            console.log(monthTasks)
            return monthTasks;
        } else {
            return [];
        }
    }

    const getCachedTasks = () => {
        let cachedTasks = localStorage.getItem('tasks');
        if(cachedTasks) {
            tasks = JSON.parse(cachedTasks);
        } else {
            tasks = [];
        }
    }

    return { tasks, addTask, getTasksByDay, getTasksByMonth, getCachedTasks }
})

