import { defineStore } from "pinia";
import { ref } from 'vue';

export const useGoalStore = defineStore('goalStore', () => {
    const goals = ref(JSON.parse(localStorage.getItem('goals') || '[]').map(goal => ({
        ...goal,
        datesCompleted: goal.datesCompleted.map(dateString => new Date(dateString))
    })));

    const getAll = async () => {
        return goals.value;
    }

    const addGoal = (goal) => {
        goals.value.push(goal);
        localStorage.setItem('goals', JSON.stringify(goals.value))
    }

    const checked = (id, isChecked) => {
        const goal = goals.value.find(g => g.id === id);
        if (goal) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const todayTime = today.getTime();

            if (isChecked) {
                // Add today's date if it's not already the last date in the array
                if (!goal.datesCompleted.length || goal.datesCompleted[goal.datesCompleted.length - 1].getTime() !== todayTime) {
                    goal.datesCompleted.push(today);
                }
            } else {
                // Remove today's date if it exists in the array
                goal.datesCompleted = goal.datesCompleted.filter(date => date.getTime() !== todayTime);
            }
            // Update streak (optional)
            updateStreak(goal);
        }
    };
    
    const updateStreak = (goal) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = today - 86400000; // 86400000 ms = 1 day

        if (goal.datesCompleted.length === 0) {
            goal.streak = 0;
        } else {
            const lastCompletedDate = goal.datesCompleted[goal.datesCompleted.length - 1].getTime();
            
            if (lastCompletedDate === today) {
                // Goal completed today, calculate streak normally
                let streak = 0;
                for (let i = goal.datesCompleted.length - 1; i >= 0; i--) {
                    const date = goal.datesCompleted[i];
                    if (date.getTime() === today - streak * 86400000) {
                        streak++;
                    } else {
                        break;
                    }
                }
                goal.streak = streak;
            } else if (lastCompletedDate === yesterday) {
                // Goal was unchecked today, decrement streak by 1
                goal.streak = Math.max(0, goal.streak - 1);
            } else {
                // Goal not completed today or yesterday, reset streak
                goal.streak = 0;
            }
        }

        // update local storage
        localStorage.setItem('goals', JSON.stringify(goals.value));
    };

    const deleteGoal = (id) => {
        goals.value = goals.value.filter(goal => goal.id !== id);
        localStorage.setItem('goals', JSON.stringify(goals.value));
    }

    return {goals, getAll, addGoal, checked, updateStreak, deleteGoal}
})