<template>
    <div class="task-component">
        <span :class="{'completed': task.completed}">{{ description }}</span>
        <CheckBox v-model="task.completed" :binary="true"/>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps(['description', 'task'])
const emit = defineEmits(['updateTask'])
const description = ref(props.description);
const task = ref(props.task);
const completed = ref(task.value.completed);

watch(completed, () => {
    emit('updateTask', task.value);
})
</script>

<style scoped>
.task-component {
    /* background-color: white;
    padding: 5px 10px 5px 10px; */
    display: flex;
    justify-content: space-between;
    margin: 5px 0px 5px 0px;
}

.completed {
    color: lightgrey;
    text-decoration: line-through;
}
</style>