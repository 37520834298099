<template>
    <div class="my-menu">
        <div v-for="button in buttons" :key="button.name" @click="menuClicked(button.name)">
            <i :class="button.icon"></i>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { defineEmits } from 'vue';

const buttons = ref([
   { name: 'home', icon: "fas fa-home" },
   { name: 'check', icon: "fas fa-check" },
   { name: 'calendar', icon: "fas fa-calendar" },
])

const emit = defineEmits(['menuItemClicked']);

const menuClicked = (buttonName) => {
    emit('menuItemClicked', buttonName);
}


</script>
<style>
.my-menu {
    display: flex;
    position: fixed;
    bottom: 0px;
    height: 4.5rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

i {
    font-size: 2rem;
}


</style>