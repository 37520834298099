<template>
        <div class="page-view">
            <div v-if="selectedPage == 'home'">
                <HomePage />
            </div>
            <div v-if="selectedPage == 'check'">
                <GoalsPage />
            </div>
            <div v-if="selectedPage == 'calendar'">
                <CalendarView />
            </div>
        </div>
        <MyMenuVue @menuItemClicked="menuClicked"/>

        
</template>

<script setup>
import {ref} from 'vue';
import MyMenuVue from "./components/MyMenu.vue";
import HomePage from './components/HomePage.vue'
import GoalsPage from './components/GoalsPage.vue';
import CalendarView from './components/CalendarView.vue';
//

const selectedPage = ref('home')
const menuClicked = (buttonName) => {
    selectedPage.value = buttonName;
}

</script>

<style>
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    background-color: rgb(255, 255, 255);
}

.page-view {
    width: 100%;
    padding: 10px;
}

.component {
    width: 100%;
    height: 100%;
}


</style>
